import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  IconButton
} from '@mui/material'
import { Close } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { Stack } from '@mui/system'

const useStyles = makeStyles({ dialog: { alignItems: 'flex-start' } })

const CommonModal = ({
  title,
  subtitle,
  noHeader = false,
  open = false,
  onClose,
  maxWidth = 'lg',
  children,
  noPadding = false,
  isAlignTop = false,
  noCloseButton = false
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={isAlignTop ? { container: classes.dialog } : {}}
      fullWidth
      maxWidth={maxWidth}
      sx={{
        '& .MuiDialog-paper': { borderRadius: '20px' }
      }}
    >
      {!noHeader ? (
        <DialogTitle
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '6px'
          }}
          p={3}
        >
          <Stack justifyContent="center">
            <Typography
              variant="h6"
              color="text.dark"
              data-testid="Typography-8a163881-73b0-45b0-bd89-c22cbeeee49d"
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="body2"
                color="text.primary"
                data-testid="Typography-9670d8d5-7308-43b2-9ebb-b03dd4a8ae66"
              >
                {subtitle}
              </Typography>
            )}
          </Stack>
          {!noCloseButton && (
            <IconButton
              sx={{ color: t => t.palette.secondary.dark }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      ) : (
        <></>
      )}
      <DialogContent sx={{ padding: 0 }}>
        {noPadding && <Grid>{children}</Grid>}
        {!noPadding && (
          <Grid container padding={3}>
            {children}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CommonModal
