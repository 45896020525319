import { DISPLAY_DESKTOPSUBMENU } from '@components/Header/Header.config'
import NavSubMenu from '@components/Header/NavbarSubMenu'
import routes from '@config/routes'
import { useTranslation } from '@contexts/translation'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  Typography
} from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

export const MenuItem = ({
  path = '',
  title,
  icon,
  baseURLs = [],
  exactURLs = [],
  excludedURLs = [],
  endornment,
  handleDrawerMobileClose = () => {},
  idList = [],
  margin = '0px'
}) => {
  const router = useRouter()
  const id = router.query.id || router.query.project_id

  let isSelected = false

  if (baseURLs?.length > 0)
    for (const url of baseURLs) {
      if (
        (url === routes.opportunities.root ||
          router.asPath == routes.opportunities.root) &&
        id &&
        idList?.includes(+id)
      )
        isSelected = true
      else if (url === routes.project.root && id && idList?.includes(+id)) {
        isSelected = false
      } else if (router.asPath.includes(url)) isSelected = true
    }
  else if (exactURLs?.length > 0)
    for (const url of exactURLs) {
      if (router.asPath === url) isSelected = true
    }

  if (excludedURLs?.length > 0)
    for (const url of excludedURLs) {
      if (router.asPath.includes(url)) isSelected = false
    }

  const closeMobileMenuHandler = () => handleDrawerMobileClose()

  return (
    <Link href={path}>
      <a>
        <ListItemButton
          onClick={() => {
            if (window.innerWidth < 1000) closeMobileMenuHandler()
          }}
          selected={isSelected}
          sx={{
            ml: '10px',
            mr: '20px',
            my: '2.5px',
            px: margin,
            py: '8px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            '&:hover': { background: t => t.palette.secondary.light },
            '&.Mui-selected': {
              '&:hover': { background: t => t.palette.secondary.light },
              background: t => t.palette.secondary.contrastText,
              boxShadow: 'none'
            }
          }}
          data-testid="ListItemButton-1857c1e7-7727-4cbc-810c-6aecfd032021"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: 'unset',
                  pl: '10px',
                  mr: '12px',
                  color: isSelected ? 'secondary.main' : 'text.primary'
                }}
              >
                {icon}
              </ListItemIcon>
            )}

            <Typography
              variant="subtitle2"
              color={'text.primary'}
              fontWeight={500}
              data-testid="Typography-ed4f56d2-12c9-4a84-b6f5-41cc69be7941"
            >
              {title}
            </Typography>
          </div>

          {endornment && (
            <ListItemIcon
              sx={{
                fontSize: '12.5px',
                minWidth: 'unset',
                color: 'text.primary'
              }}
            >
              {endornment}
            </ListItemIcon>
          )}
        </ListItemButton>
      </a>
    </Link>
  )
}

export const CollapsableMenuItems = ({
  path = '',
  title,
  icon,
  exactURLs,
  baseURLs,
  excludedURLs,
  idList = [],
  endornment,
  handleDrawerMobileClose = () => {},
  section = null
}) => {
  const router = useRouter()
  const { dictionary } = useTranslation()

  const [open, setOpen] = useState(false)

  const id = router.query.id || router.query.project_id

  let isSelected = false

  if (baseURLs?.length > 0)
    for (const url of baseURLs) {
      if (url === routes.opportunities.root && id && idList?.includes(+id))
        isSelected = true
      else if (url === routes.project.root && id && idList?.includes(+id)) {
        isSelected = false
      } else if (router.asPath.includes(url)) isSelected = true
    }
  else if (exactURLs?.length > 0)
    for (const url of exactURLs) {
      if (router.asPath === url) isSelected = true
    }

  if (excludedURLs?.length > 0) {
    for (const url of excludedURLs) {
      if (router.asPath.includes(url)) isSelected = false
    }
  }

  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <ListItemButton
          onClick={() => setOpen(!open)}
          selected={isSelected}
          sx={{
            pl: 0,
            py: '8px',
            '&.Mui-selected': {
              '&:hover': { background: t => t.palette.secondary.light },
              background: t => t.palette.secondary.light,
              boxShadow: t => `inset -2px 0px 0px ${t.palette.secondary.main}`
            }
          }}
          data-testid="ListItemButton-7d85a678-4624-469c-b12b-e2bdb998617b"
        >
          {icon && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '28px',
                mr: '12px',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {icon}
            </ListItemIcon>
          )}

          <Typography
            variant={'subtitle2'}
            color={isSelected ? 'secondary.main' : 'text.primary'}
            data-testid="Typography-0f3462f1-a1df-4f03-b8ae-b1056c7ead87"
          >
            {title}
          </Typography>

          {endornment && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '40px',
                mr: '0',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {endornment}
            </ListItemIcon>
          )}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            {NavSubMenu?.[section ? section : title]?.length > 0 &&
              NavSubMenu?.[section ? section : title]?.map((item, i) => (
                <MenuItem
                  key={i}
                  path={item.link}
                  title={dictionary[`${item.display_name}`]}
                  exactURLs={item.exactURL ? [item.exactURL] : []}
                  baseURLs={item.exactURL ? [] : [item.link]}
                  handleDrawerMobileClose={handleDrawerMobileClose}
                  idList={idList}
                  margin={'40px'}
                  data-testid="MenuItem-5134ec41-8954-4cb4-a98f-a46ea95d8db6"
                />
              ))}
          </List>
        </Collapse>
      </Box>

      <Box
        sx={{
          display: { xs: 'none', md: DISPLAY_DESKTOPSUBMENU ? 'block' : 'none' }
        }}
      >
        <ListItemButton
          selected={isSelected}
          onClick={
            () => setOpen(!open)
            // event => handleMClick(event, title)
          }
          sx={{
            ml: '10px',
            mr: '20px',
            my: '2.5px',
            px: 0,
            py: '8px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            '&:hover': { background: t => t.palette.secondary.light },
            '&.Mui-selected': {
              '&:hover': { background: t => t.palette.secondary.light },
              background: t => t.palette.secondary.contrastText,
              boxShadow: 'none'
            }
          }}
          data-testid="ListItemButton-5a904068-8e7a-4edd-a5e1-3bb2624d6444"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: 'unset',
                  pl: '10px',
                  mr: '12px',
                  color: isSelected ? 'secondary.main' : 'text.primary'
                }}
              >
                {icon}
              </ListItemIcon>
            )}

            <Typography
              variant={'subtitle2'}
              color={'text.primary'}
              fontWeight={500}
              data-testid="Typography-af4dce1c-c796-4631-87ab-8055e71141b7"
            >
              {title}
            </Typography>
          </div>

          {endornment && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                color: 'text.primary'
              }}
            >
              {endornment}
            </ListItemIcon>
          )}
        </ListItemButton>

        <Collapse id="basic-menu" in={open} timeout="auto" unmountOnExit>
          {NavSubMenu?.[section ? section : title]?.length > 0 &&
            NavSubMenu?.[section ? section : title]?.map((item, i) => (
              <MenuItem
                key={i}
                path={item.link}
                title={dictionary[`${item.display_name}`]}
                exactURLs={item.exactURL ? [item.exactURL] : []}
                baseURLs={item.exactURL ? [] : [item.link]}
                handleDrawerMobileClose={handleDrawerMobileClose}
                idList={idList}
                margin={'40px'}
                data-testid="MenuItem-ed170f78-1984-4cf4-9c49-d02d0ad58a34"
              />
            ))}
        </Collapse>
        {/* </Menu> */}
      </Box>

      {!DISPLAY_DESKTOPSUBMENU && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <MenuItem
            path={path}
            title={title}
            icon={icon}
            exactURLs={exactURLs}
            baseURLs={baseURLs}
            excludedURLs={excludedURLs}
            endornment={endornment}
            handleDrawerMobileClose={handleDrawerMobileClose}
            idList={idList}
            data-testid="MenuItem-65eb5a07-1cb7-499a-8c47-4069086ec28a"
          />
        </Box>
      )}
    </>
  )
}
