import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Avatar,
  Button,
  Toolbar,
  Typography,
  Drawer,
  AppBar,
  Stack,
  Badge,
  IconButton,
  Chip,
  Popover,
  ListItemButton
} from '@mui/material'
import {
  NotificationsNone as NotificationsIcon,
  Settings
  // Circle
} from '@mui/icons-material'

import API_URL from '@config/services'
import routes from '@config/routes'
import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import PopoverMenu from '@components/Header/Popover/PopoverMenu'
import HeaderActionsTable from '@components/Header/Table'
import TopViewSelector from '@components/common/TopViewSelector'

import useStyles from '@components/Header/styles'
import { projectsTopBarURL } from '@api/projects'
import { updateProfile } from '@api/profiles'
import NotificationsTopBar from '@components/Notifications/NotificationsTopBar'
// import { getUnreadNotifs } from '@api/digests'
import { checkPerms } from '@utils/checkPerms'
import CommonModal from '@components/common/Modal/CommonModal'
import useChipStyles from '@styles/chip/chip'
import clsx from 'clsx'
// import Flag from 'react-world-flags'
import { mutate } from 'swr'
// import { responseHandler } from '@utils/responseHandler'
// import { useSnackbar } from 'notistack'

const LanguagesSelector = () => {
  const { lang, setLang } = useTranslation()
  const { isAuthenticated } = useAuth()

  const changeLanguageHandler = async id => {
    setLang(id)
    if (!isAuthenticated) {
      localStorage.setItem('OOTI-locale', id)
    } else {
      await updateProfile({ locale: id })
      mutate(API_URL.COUNTRIES.GET_COUNTRIES)
      mutate(API_URL.LANGUAGES.GET_LANGUAGES)
      localStorage.setItem('OOTI-locale', id)
    }
  }

  const LanguageDisplay = ({ imageName, languageName, displayName = true }) => {
    return (
      <Stack direction="row" alignItems={'center'} spacing={1}>
        <Avatar
          src={`/flags/${imageName}.png`}
          sx={{ height: 18, width: 18 }}
        />

        {displayName && (
          <span data-testid="span-8e10eceb-62a2-4bf9-ad2d-130549fc99c0">
            {languageName}
          </span>
        )}
      </Stack>
    )
  }

  const _languages = [
    {
      name: (
        <LanguageDisplay
          imageName="france"
          languageName={'Français'}
          displayName={!isAuthenticated}
        />
      ),

      nameMenu: (
        <LanguageDisplay imageName="france" languageName={'Français'} />
      ),

      id: 'fr',
      onClick: () => changeLanguageHandler('fr')
    },
    {
      name: (
        <LanguageDisplay
          imageName="united-kingdom"
          languageName={'English'}
          displayName={!isAuthenticated}
        />
      ),

      nameMenu: (
        <LanguageDisplay imageName="united-kingdom" languageName={'English'} />
      ),

      id: 'en',
      onClick: () => changeLanguageHandler('en')
    },
    {
      name: (
        <LanguageDisplay
          imageName="italy"
          languageName={'Italiano'}
          displayName={!isAuthenticated}
        />
      ),

      nameMenu: <LanguageDisplay imageName="italy" languageName={'Italiano'} />,
      id: 'it',
      onClick: () => changeLanguageHandler('it')
    },
    {
      name: (
        <LanguageDisplay
          imageName="spain"
          languageName={'Español'}
          displayName={!isAuthenticated}
        />
      ),

      nameMenu: <LanguageDisplay imageName="spain" languageName={'Español'} />,
      id: 'es',
      onClick: () => changeLanguageHandler('es')
    },
    {
      name: (
        <LanguageDisplay
          imageName="portugal"
          languageName={'Português'}
          displayName={!isAuthenticated}
        />
      ),

      nameMenu: (
        <LanguageDisplay imageName="portugal" languageName={'Português'} />
      ),

      id: 'pt',
      onClick: () => changeLanguageHandler('pt')
    },
    {
      name: (
        <LanguageDisplay
          imageName="netherlands"
          languageName={'Nederlands'}
          displayName={!isAuthenticated}
        />
      ),

      nameMenu: (
        <LanguageDisplay imageName="netherlands" languageName={'Nederlands'} />
      ),

      id: 'nl',
      onClick: () => changeLanguageHandler('nl')
    },
    {
      name: (
        <LanguageDisplay
          imageName="germany"
          languageName={'Deutsch'}
          displayName={!isAuthenticated}
        />
      ),

      nameMenu: (
        <LanguageDisplay imageName="germany" languageName={'Deutsch'} />
      ),

      id: 'de',
      onClick: () => changeLanguageHandler('de')
    }
  ]

  const langIndex = _languages.findIndex(l => l.id === lang)

  return (
    <TopViewSelector
      options={_languages}
      nameField="nameMenu"
      idField="id"
      defaultIndex={langIndex}
      labelField="name"
      arrow={false}
    />
  )
}

const MenuProfilePicture = () => {
  return (
    <ListItemButton
      sx={{
        width: '100px'
      }}
      data-testid="ListItemButton-c411ef5d-41d1-4fbf-9417-f75ec9770609"
    >
      <Link href={routes.root} passHref>
        <Box
          component="img"
          src={'/keysy_logo_light.png'}
          sx={{
            cursor: 'pointer',
            height: '20px',
            width: 'auto'
          }}
        />
      </Link>
    </ListItemButton>
  )
}

const TopBar = ({ opportunities, handleDrawerToggle }) => {
  const { dictionary } = useTranslation()
  const {
    isAuthenticated,
    organization,
    organizationTeam,
    permissions,
    // membership,
    viewAs,
    // setOrganizationTeam,
    user,
    orgSettings
    // logout
  } = useAuth()
  // const { enqueueSnackbar } = useSnackbar()
  const router = useRouter()
  const classes = useStyles()
  const classesChip = useChipStyles()

  // const [teams, setTeams] = useState(null)
  const [notifsUnread, setNotifsUnread] = useState(null)
  // const [alerts, setAlerts] = useState(null)
  const [notifsModal, setNotifsModal] = useState(false)
  // const [isLoadingNotifs, setIsLoadingNotifs] = useState(false)
  // const [isFirstAuth, setIsFirstAuth] = useState(isAuthenticated)
  const [isSwitchingCompany, setIsSwitchingCompany] = useState(false)
  const [projectsList, setProjectsList] = useState([])

  const { data: organizations } = useFetchData(
    isAuthenticated ? API_URL.ORG_USER.GET_ORGANIZATIONS : null
  )

  // const { data: teamsList } = useFetchData(
  //   isAuthenticated && organization
  //     ? API_URL.TEAMS.GET_TEAMS_USER_LIST + '?orguser=' + membership?.orguser
  //     : null
  // )
  const { data: projects } = useFetchData(
    isAuthenticated && organization ? projectsTopBarURL : null
  )

  const is_admin = checkPerms({
    is_admin: true,
    org: organization,
    team: organizationTeam,
    perms: permissions
  })

  // useEffect(async () => {
  //   const results = teamsList?.results.map(t => t.team)
  //   if (results && results.length > 1) {
  //     setTeams(results)
  //   }
  // }, [teamsList])

  // const notifsURL = API_URL.DIGESTS.GET_DIGESTS_INBOX_UNREAD
  // const { data: notifsList, isLoading: isLoadingNotifs } =
  //   useFetchData(notifsURL)
  // useEffect(() => {
  //   const getNotifs = async () => {
  //     setIsLoadingNotifs(true)
  //     const notifsList = await getUnreadNotifs()
  //     if (notifsList?.status === 401) logout()
  //     else if (notifsList) setNotifsUnread(notifsList.data?.unread_list)

  //     // if (is_admin) {
  //     //   const alertsList = await getAlerts(organization)
  //     //   if (alertsList?.status === 401) logout()
  //     //   else if (alertsList) setAlerts(alertsList.data)
  //     // }
  //     setIsLoadingNotifs(false)
  //   }
  //   if (isFirstAuth && organization) getNotifs()
  //   const interval = setInterval(async () => {
  //     if (isAuthenticated) getNotifs()
  //   }, 1000 * 60 * 30) // every 30 minutes

  //   return () => {
  //     setIsFirstAuth(false)
  //     clearInterval(interval)
  //   }
  // }, [isAuthenticated])

  // const changeTeamHandler = async pk => {
  //   const response = await updateProfile({ selected_team: pk })
  //   setOrganizationTeam(pk)

  //   responseHandler({
  //     response,
  //     callback: async () => Router.reload(),
  //     dictionary,
  //     snackbar: enqueueSnackbar
  //   })
  // }

  const onCloseSwitchCompany = () => setIsSwitchingCompany(false)

  const [openselect, setOpenselect] = useState(false)

  // let selectedTeamIndex = teams?.length
  //   ? [...teams].findIndex(team => team.id === organizationTeam)
  //   : 0
  // selectedTeamIndex = selectedTeamIndex != -1 ? selectedTeamIndex + 1 : 0

  /* const getProjectLink = id => {
    if (router.pathname.includes(routes.project.fees.root))
      return `${routes.project.fees.summary}/${id}`
    else if (
      router.pathname.includes(routes.project.root) &&
      !router.pathname.includes(routes.projects.root)
    ) {
      let query = ''
      if (Object.keys(router.query).includes('id')) query = '[id]'
      else if (Object.keys(router.query).includes('project_id'))
        query = '[project_id]'
      let url = router.pathname.split('/')
      const idx = url.indexOf(query)
      if (idx >= 0) url[idx] = id
      return url.join('/')
    }
    return `${routes.project.summary}/${id}`
  } */

  const getProjectLink = id => {
    if (router.pathname.includes('/project/')) {
      return router.pathname.replace('[id]', id)
    } else return `${routes.project.summary}/${id}`
  }

  useEffect(async () => {
    if (projects) {
      setProjectsList(
        projects?.results.map(p => ({
          display: p.display,
          id: p.id,
          link: getProjectLink(p.id)
        }))
      )
    }
  }, [projects, router.pathname])

  useEffect(async () => {
    if (opportunities) {
      //   setOppsList(
      //     opportunities?.results.map(o => ({
      //       display: o.display,
      //       id: o.id,
      //       link: getProjectLink(o.id),
      //       isHidden: !view_pipelines
      //     }))
      //   )
    }
  }, [opportunities, router.pathname])
  const { project_id, id } = router.query
  const projectId = id ? id : project_id
  return (
    <AppBar
      id="topBar"
      data-cy={'top-bar'}
      sx={{
        backgroundColor: 'primary.main',
        boxShadow: 'none',
        minHeight: '42px',
        top: -8
      }}
    >
      {isAuthenticated && (
        <Toolbar sx={{ paddingX: 0 }}>
          <MenuProfilePicture />
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                color="secondary"
                variant="contained"
                disableElevation
                className={classes.toggleBtn}
                onClick={handleDrawerToggle}
                data-testid="Button-9c8e5b55-ca7d-4528-9b05-027bbc79911b"
              >
                <MenuIcon />
              </Button>
              {orgSettings?.is_demo && (
                <Chip
                  className={clsx(classesChip.warning, classes.DeskMenu)}
                  label={dictionary.demo}
                  data-testid="Chip-70bf7e4d-8cdc-4dc3-b55a-0c298d7ce440"
                />
              )}

              {projects && projects.results && user.role !== 'tenant' && (
                <TopViewSelector
                  label={!projectId ? dictionary.projects : null}
                  options={[
                    {
                      display: <b>{dictionary.projects}</b>,
                      id: null,
                      link: routes.projects.root
                    },
                    ...projectsList
                  ]}
                  nameField="display"
                  idField="id"
                  searchBar
                  defaultIndex={
                    projects?.results.findIndex(p => p.id == projectId) + 1 ||
                    null
                  }
                />
              )}
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              {/* <Box className={classes.avatarContainer}> */}

              {viewAs && (
                <a href={`${routes.root}?view_as=null`}>
                  <Typography
                    variant="body2"
                    color="white"
                    component="div"
                    ml="12px"
                    data-testid="Typography-ad363df4-94a8-4c06-a1b0-9d5a66f609ba"
                  >
                    <span
                      style={{ textTransform: 'uppercase' }}
                      data-testid="span-a98ccdf0-605e-4b47-b2ac-35daf0aeb08c"
                    >
                      {dictionary.viewing_as}: {viewAs}
                    </span>
                  </Typography>
                </a>
              )}

              <span
                className={classes.DeskMenu}
                data-testid="span-f1daa958-5ed8-4b39-9832-f0f9605434c1"
              >
                {is_admin && orgSettings?.is_trial && !orgSettings?.is_demo && (
                  <Link href={routes.account.subscription}>
                    <Button
                      color="white"
                      variant="contained"
                      sx={{
                        ml: '22px',
                        mr: '35px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      disableElevation
                      data-testid="Button-3f26a729-4cb9-4d2a-b5cd-fdc32626f241"
                    >
                      {dictionary.activate_account}
                    </Button>
                  </Link>
                )}

                {is_admin && orgSettings?.is_demo && (
                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      ml: '22px',
                      mr: '35px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    disableElevation
                    onClick={() => setIsSwitchingCompany(true)}
                    data-testid="Button-78c1e103-af49-45aa-bb10-be06b36e9df7"
                  >
                    {dictionary.activate_account}
                  </Button>
                )}
              </span>

              {/* <Chip label={'BETA'} className={classesChip.secondaryLight} /> */}

              {orgSettings && user.role !== 'tenant' && (
                <>
                  <span
                    className={classes.DeskMenu}
                    data-testid="span-d5e648f8-00bd-4680-878c-004a199c2e30"
                  >
                    <Link href={routes.account.profile.info} passHref>
                      <IconButton
                        sx={{ color: 'white' }}
                        data-testid="IconButton-131ef6fe-819f-4943-be64-81d929bc681b"
                      >
                        <Settings sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Link>
                  </span>

                  <IconButton
                    sx={{
                      color: 'white',
                      width: '40px',
                      mx: '16px'
                    }}
                    onClick={() => setNotifsModal(prev => !prev)}
                    data-testid="IconButton-ac0a3c85-0779-4084-b072-73f80881de1a"
                  >
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={
                        !notifsUnread || (notifsUnread && !notifsUnread.length) //&&
                        // (!alerts || (alerts && !alerts.length))
                      }
                    >
                      <NotificationsIcon sx={{ fontSize: 18 }} />
                    </Badge>
                  </IconButton>
                </>
              )}

              <Stack direction="row">
                <Chip
                  clickable
                  icon={<Avatar sx={{ width: 15, height: 15 }} />}
                  onClick={event => {
                    setOpenselect(event.currentTarget)
                  }}
                  sx={{
                    height: 'auto',
                    mx: 'auto',
                    padding: '5px',
                    background: 'unset',
                    borderRadius: '10px'
                  }}
                  label={
                    <Typography
                      variant="subtitle2"
                      color={'white'}
                      component="div"
                      ml="5px"
                      className={classes.DeskMenu}
                      fontWeight="bold"
                      data-testid="Typography-04be6cf6-2fa7-4df9-bf5b-db9a63117659"
                    >
                      {user && user?.first_name}&nbsp;
                      {/* <span style={{ textTransform: 'uppercase' }}>
                {user && user?.last_name}
                </span> */}
                    </Typography>
                  }
                  data-cy={'user-settings-menu-icon'}
                  data-testid="Chip-99b611b0-718c-4a16-acea-1daef83c1950"
                />

                <Popover
                  open={Boolean(openselect)}
                  anchorEl={openselect}
                  onClose={() => {
                    setOpenselect(false)
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <PopoverMenu
                    setIsSwitchingCompany={setIsSwitchingCompany}
                    switchCompany={organizations?.organizations.length > 1}
                  />
                </Popover>
              </Stack>
              {/* </Box> */}
            </Stack>
          </Stack>

          <Drawer
            open={notifsModal}
            onClose={() => setNotifsModal(false)}
            anchor="right"
            classes={{ paper: classes.notifsDrawer }}
          >
            <NotificationsTopBar
              onClose={() => setNotifsModal(false)}
              notifsUnread={notifsUnread}
              setNotifsUnread={setNotifsUnread}
              // isLoadingNotifs={isLoadingNotifs}
            />
          </Drawer>
          <span
            className={classes.DeskMenu}
            data-testid="span-fbcb8560-04a1-4728-948e-6c32996d3b37"
          >
            <LanguagesSelector />
          </span>
        </Toolbar>
      )}

      {!isAuthenticated && (
        <Toolbar disableGutters={false}>
          <Box className={classes.grow}>
            <Link href={routes.root}>
              <a>
                <Image src="/ooti_logo_light.png" width={75} height={40} />
              </a>
            </Link>
          </Box>

          <Box className={classes.DeskMenu}>
            <Button
              color="primary"
              data-testid="Button-fcd34d6e-81d1-4ce9-9b3c-9da96345c573"
            >
              <Link href={routes.account.signup}>
                <a>{dictionary.signup}</a>
              </Link>
            </Button>
            <Button
              color="secondary"
              sx={{ marginLeft: theme => theme.spacing(2) }}
              data-testid="Button-33c743ff-86d0-4e2b-91cd-a2952489053b"
            >
              <Link href={routes.account.login} data-cy={'login-menu-link'}>
                <a>{dictionary.login}</a>
              </Link>
            </Button>
          </Box>
          <LanguagesSelector />
        </Toolbar>
      )}

      <CommonModal
        open={isSwitchingCompany}
        title={dictionary.switch_company}
        onClose={onCloseSwitchCompany}
        maxWidth={'sm'}
      >
        <HeaderActionsTable handleClose={onCloseSwitchCompany} />
      </CommonModal>
    </AppBar>
  )
}

export default TopBar
