import React from 'react'

import { Box, List, Chip, ListItemButton, Skeleton } from '@mui/material'
import {
  Group,
  Description,
  Timeline,
  Contacts,
  ContentPaste,
  Receipt,
  ReceiptLong,
  Approval,
  Circle,
  ConfirmationNumber,
  CreditCard,
  Forum,
  Dashboard,
  KeyboardArrowDown,
  Help
} from '@mui/icons-material'

import routes from '@config/routes'
import { useTranslation } from '@contexts/translation'

import useStyles from '@components/Header/styles'
import {
  CollapsableMenuItems,
  MenuItem
} from '@components/common/Menu/MenuItem'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import { useAuth } from '@contexts/auth'
import { checkPerms } from '@utils/checkPerms'
import useChipStyles from '@styles/chip/chip'
import Link from 'next/link'
import queryString from '@utils/queryString'

const MenuProfilePicture = () => {
  const { organization } = useAuth()
  const { data: orgInfo, isLoading } = useFetchData(
    API_URL.ORGANIZATIONS.GET_ORGANIZATION(organization)
  )

  return (
    <ListItemButton
      sx={{
        px: theme => theme.spacing(2),
        py: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}
      data-testid="ListItemButton-6fbafb5d-2504-4cff-9d29-fc88c456af72"
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {isLoading && (
          <Skeleton width={'150px'} height={'150px'} variant="rectangular">
            <div />
          </Skeleton>
        )}
        {!isLoading && orgInfo && !orgInfo.logo && (
          <Link href={routes.account.settings.general.organization} passHref>
            <Box
              component="img"
              src={'/Placeholders/logo_placeholder.png'}
              sx={{
                cursor: 'pointer',
                height: '150px',
                width: '150px',
                borderRadius: theme => theme.spacing(1)
              }}
            />
          </Link>
        )}
        {!isLoading && orgInfo && orgInfo.logo && (
          <Link href={routes.root} passHref>
            <Box
              sx={{
                cursor: 'pointer',
                height: '150px',
                width: '150px',
                borderRadius: theme => theme.spacing(1),
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${orgInfo.logo})`
              }}
            />
          </Link>
        )}
      </Box>
    </ListItemButton>
  )
}

const DrawerContent = ({ opportunitiesIDs, handleDrawerToggle }) => {
  const classes = useStyles()
  const classesChip = useChipStyles()
  const { dictionary, t } = useTranslation()
  const {
    isAuthenticated,
    organization,
    orgSettings,
    organizationTeam,
    permissions,
    membership
  } = useAuth()

  const { data: badgeCounts } = useFetchData(
    isAuthenticated && organizationTeam
      ? API_URL.ORG_USER.GET_VALIDATION_METRICS + '?team=' + organizationTeam
      : null
  )

  const routesMutable =
    API_URL.ORG_USER.GET_ORG_USER(membership?.orguser, organizationTeam) +
    '&' +
    queryString({ with_tags: true, with_custom_fields: true })
  const { data: orgUser } = useFetchData(
    membership?.orguser ? routesMutable : null
  )

  const manage_worklogs = checkPerms({
    perm: ['manage_worklogs'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const time_validation_enabled = orgUser?.time_validation_enabled
  const manage_timeoff_requests = checkPerms({
    perm: ['manage_timeoff_requests'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const timeoff_validation_enabled = orgUser?.timeoff_validation_enabled
  const expenses_validation_enabled = orgUser?.expenses_validation_enabled
  const view_members = checkPerms({
    perm: ['view_members'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_members = checkPerms({
    perm: ['manage_members'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const view_finance_analytics = checkPerms({
    perm: ['view_finance_analytics'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_costs = checkPerms({
    perm: ['manage_costs'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_payroll = checkPerms({
    perm: ['manage_payroll'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_freelancers = checkPerms({
    perm: ['manage_freelancers'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })

  const view_invoices = checkPerms({
    perm: ['view_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_invoices = checkPerms({
    perm: ['manage_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  /* to uncomment when API get invoices with view_invoices_projects & manage_invoices_projects permissions */
  const view_invoices_projects = checkPerms({
    perm: ['view_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions,
    checkAllProjects: true
  })
  const manage_invoices_projects = checkPerms({
    perm: ['manage_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions,
    checkAllProjects: true
  })

  const validationCounts = badgeCounts?.total || 0
  const hoursToValidate = badgeCounts?.team_counts?.timelogs > 0

  return (
    <>
      <Box className={classes.list} sx={{ mt: 5.5 }}>
        <List>
          <MenuProfilePicture />
          <MenuItem
            path={routes.account.onboarding}
            title={t('configuration')}
            icon={<Help sx={{ fontSize: '20px' }} />}
            exactURLs={[routes.account.onboarding]}
            handleDrawerMobileClose={handleDrawerToggle}
          />

          <MenuItem
            path={routes.root}
            title={dictionary.dashboard}
            icon={<Dashboard sx={{ fontSize: '20px' }} />}
            exactURLs={[routes.root]}
            handleDrawerMobileClose={handleDrawerToggle}
            data-testid="MenuItem-7e5803df-806d-406e-b7d2-26e88390347a"
          />

          {/* PROJECTS */}
          {view_finance_analytics && (
            <MenuItem
              path={routes.projects.summary}
              title={dictionary.projects}
              icon={<Description sx={{ fontSize: '20px' }} />}
              baseURLs={[routes.projects.summary, routes.project.root]}
              handleDrawerMobileClose={handleDrawerToggle}
              data-testid="MenuItem-231359ae-c000-4a7d-ae63-0100a667c436"
            />
          )}

          {!view_finance_analytics && (
            <MenuItem
              path={routes.projects.root}
              title={dictionary.projects}
              icon={<Description sx={{ fontSize: '20px' }} />}
              baseURLs={[routes.projects.root, routes.project.root]}
              handleDrawerMobileClose={handleDrawerToggle}
              data-testid="MenuItem-ebc5d30b-3407-4cc3-80ff-e2f89d66ec0c"
            />
          )}

          {/* <MenuTitle title={dictionary.team} innerSideMenu={false} /> */}

          {view_members && !manage_members && (
            <MenuItem
              path={routes.teams.collaborators}
              title={dictionary.collaborators}
              icon={<Group sx={{ fontSize: '20px' }} />}
              baseURLs={[routes.teams.root]}
              handleDrawerMobileClose={handleDrawerToggle}
              data-testid="MenuItem-90af01be-7af2-4ee6-a72e-2641e19826ea"
            />
          )}

          {(manage_worklogs ||
            manage_timeoff_requests ||
            time_validation_enabled ||
            timeoff_validation_enabled ||
            expenses_validation_enabled) && (
            <MenuItem
              path={
                manage_worklogs
                  ? routes.validation.expenses
                  : routes.validation.timeoff
              }
              title={dictionary.validation}
              icon={<Approval sx={{ fontSize: '20px' }} />}
              baseURLs={[routes.validation.root]}
              handleDrawerMobileClose={handleDrawerToggle}
              endornment={
                validationCounts > 0 ? (
                  <Chip
                    size="small"
                    label={validationCounts}
                    className={classesChip.warning}
                    data-testid="Chip-bbad3d57-6737-449d-9439-33c3d3437525"
                  />
                ) : hoursToValidate ? (
                  <Circle fontSize="small" color="warning" />
                ) : null
              }
              data-testid="MenuItem-1d410595-af32-4ec1-b569-ff13f5041cfa"
            />
          )}

          {/* <MenuTitle title={dictionary.management} innerSideMenu={false} /> */}

          {(manage_costs || manage_payroll || manage_freelancers) && (
            <CollapsableMenuItems
              section={'costs'}
              path={
                manage_costs ? routes.costs.summary : routes.costs.contracts
              }
              title={dictionary.costs.root}
              icon={<Timeline sx={{ fontSize: '20px' }} />}
              baseURLs={[routes.costs.root]}
              excludedURLs={[
                routes.costs.my_expenses,
                routes.costs.expense_items,
                routes.project.costs.root,
                routes.finances.root
              ]}
              handleDrawerMobileClose={handleDrawerToggle}
              endornment={<KeyboardArrowDown sx={{ fontSize: '20px' }} />}
            />
          )}

          {(view_invoices ||
            view_invoices_projects ||
            manage_invoices ||
            manage_invoices_projects) && (
            <CollapsableMenuItems
              section={'invoices'}
              path={
                manage_invoices
                  ? routes.invoicing.summary
                  : routes.invoicing.invoices
              }
              title={dictionary.invoices}
              icon={<Receipt sx={{ fontSize: '20px' }} />}
              baseURLs={[routes.invoicing.root]}
              excludedURLs={[routes.project.root]}
              handleDrawerMobileClose={handleDrawerToggle}
              endornment={<KeyboardArrowDown sx={{ fontSize: '20px' }} />}
            />
          )}

          <MenuItem
            path={routes.cashflow.root}
            title={dictionary.cashflow}
            icon={<ReceiptLong sx={{ fontSize: '20px' }} />}
            exactURLs={[routes.cashflow.root]}
            handleDrawerMobileClose={handleDrawerToggle}
            data-testid="MenuItem-33045caf-8d11-4e81-82de-0b9890029172"
          />

          {orgSettings?.finance_enabled && (
            <>
              {/* <MenuTitle title={dictionary.finance} innerSideMenu={false} /> */}
              <CollapsableMenuItems
                section={'finances'}
                path={routes.investors.root}
                title={dictionary.finance}
                icon={<CreditCard sx={{ fontSize: '20px' }} />}
                baseURLs={[routes.investors.root, routes.internal.root]}
                handleDrawerMobileClose={handleDrawerToggle}
                endornment={<KeyboardArrowDown sx={{ fontSize: '20px' }} />}
              />
            </>
          )}

          <MenuItem
            path={routes.reports.root}
            title={dictionary.reports}
            icon={<ContentPaste sx={{ fontSize: '20px' }} />}
            exactURLs={[routes.reports.root]}
            handleDrawerMobileClose={handleDrawerToggle}
            data-testid="MenuItem-68aa758c-b159-42c3-ae28-455ca4820fdf"
          />

          <MenuItem
            path={routes.ticketing.root}
            title={dictionary.ticketing}
            icon={<ConfirmationNumber sx={{ fontSize: '20px' }} />}
            exactURLs={[routes.ticketing.root]}
            handleDrawerMobileClose={handleDrawerToggle}
            data-testid="MenuItem-e951f65d-d684-4768-a936-15ab44be6489"
          />

          {/* <MenuTitle title={dictionary.collaboration} innerSideMenu={false} /> */}
          <CollapsableMenuItems
            section={'collaboration'}
            path={routes.collaboration.root}
            title={dictionary.collaboration}
            icon={<Forum sx={{ fontSize: '20px' }} />}
            baseURLs={[routes.collaboration.root]}
            excludedURLs={['/project/collaboration/']}
            endornment={<KeyboardArrowDown sx={{ fontSize: '20px' }} />}
            handleDrawerMobileClose={handleDrawerToggle}
          />
          {manage_costs && (
            <MenuItem
              path={routes.contacts.root}
              title={dictionary.contacts}
              icon={<Contacts sx={{ fontSize: '20px' }} />}
              exactURLs={[
                routes.contacts.root,
                routes.contacts.suppliers,
                routes.contacts.tenants,
                routes.contacts.landlords
              ]}
              handleDrawerMobileClose={handleDrawerToggle}
              data-testid="MenuItem-7e8dd362-3531-46a1-9c35-7666f9181f7a"
            />
          )}
        </List>
      </Box>
    </>
  )
}

export default DrawerContent
