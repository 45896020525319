import { useRouter } from 'next/router'
import Link from 'next/link'
import { Divider, MenuItem, MenuList, Paper } from '@mui/material'
import {
  Build,
  Storage,
  Person,
  Groups,
  Logout,
  CreditCard,
  CorporateFare,
  FormatListBulleted
} from '@mui/icons-material'

import routes from '@config/routes'
import { useAuth } from '@contexts/auth'
import { checkPerms } from '@utils/checkPerms'
import { useTranslation } from '@contexts/translation'
// import { useFeatureFlagEnabled } from 'posthog-js/react'

import useStyles from './popoverMenuStyles'

const PopoverMenu = ({ setIsSwitchingCompany, switchCompany }) => {
  const router = useRouter()
  const classes = useStyles()
  const { dictionary, t } = useTranslation()
  const { organization, permissions, logout, user } = useAuth()
  const hasPerm = checkPerms({
    is_admin: true,
    org: organization,
    perms: permissions
  })

  // const flagEnabled = useFeatureFlagEnabled('usage-page')

  const path = router.pathname

  const handleSwitchCompany = () => setIsSwitchingCompany(true)

  return (
    <Paper className={classes.menu}>
      <MenuList
        className={classes.menuContentList}
        data-cy={'user-settings-menu'}
      >
        {user.role === 'tenant' ? (
          <>
            <Link href={routes.account.profile.info}>
              <a>
                {/* Profile */}
                <MenuItem
                  classes={{
                    selected: classes.menuSelected
                  }}
                  selected={path.includes(routes.account.profile.root)}
                  data-testid="MenuItem-e646ede8-e9cc-412e-b22c-bec7ce4be243"
                >
                  <Person className={classes.menuIcon} />
                  {dictionary.profile}
                </MenuItem>
              </a>
            </Link>

            <MenuItem
              onClick={logout}
              data-testid="MenuItem-a7644685-0704-42be-a9d0-c86aaad64f9d"
            >
              <Logout className={classes.menuIcon} />
              {dictionary.logout}
            </MenuItem>
          </>
        ) : (
          <>
            {organization && (
              <div>
                <Link href={routes.account.profile.info}>
                  <a>
                    {/* Profile */}
                    <MenuItem
                      classes={{
                        selected: classes.menuSelected
                      }}
                      selected={path.includes(routes.account.profile.root)}
                      data-testid="MenuItem-c1b21950-e558-4369-9c63-5d46cd33a002"
                    >
                      <Person className={classes.menuIcon} />
                      {dictionary.profile}
                    </MenuItem>
                  </a>
                </Link>

                {/* Settings */}
                {hasPerm && (
                  <Link href={routes.account.settings.general.organization}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={path.includes(routes.account.settings.root)}
                        data-testid="MenuItem-eb7d6c18-a29d-4e9e-9661-86e3373167a9"
                      >
                        <Build className={classes.menuIcon} />
                        {dictionary.settings}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                {/* Data */}
                {hasPerm && (
                  <Link href={routes.account.data.exports}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={
                          path.includes(routes.account.data.root) &&
                          !path.includes(routes.account.data.usage)
                        }
                        data-testid="MenuItem-02075afd-9977-430b-883a-69d2968ffd43"
                      >
                        <Storage className={classes.menuIcon} />
                        {dictionary.data}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                {/* Indexes */}
                {hasPerm && (
                  <Link href={routes.indexes.root}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={path.includes(routes.indexes.root)}
                      >
                        <FormatListBulleted className={classes.menuIcon} />
                        {t('indexes')}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                {/* Users */}
                {hasPerm && (
                  <Link href={routes.account.users.users}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={path.includes(routes.account.users.root)}
                        data-testid="MenuItem-6a5c8496-71da-40d7-a8e0-fbea77dc016b"
                      >
                        <Groups className={classes.menuIcon} />
                        {dictionary.users}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                {/* Subscription */}
                {hasPerm && (
                  <>
                    <Link href={routes.account.data.usage}>
                      <a>
                        <MenuItem
                          classes={{
                            selected: classes.menuSelected
                          }}
                          data-testid="MenuItem-8c570cd8-0f6f-4206-b4f6-e7ccc971081a"
                        >
                          <CreditCard className={classes.menuIcon} />
                          {dictionary.subscription}
                        </MenuItem>
                      </a>
                    </Link>
                    <Divider />
                  </>
                )}

                {/* Switch company */}
                {switchCompany && (
                  <>
                    <MenuItem
                      onClick={handleSwitchCompany}
                      data-testid="MenuItem-aa006c55-4b05-4f8b-8a01-5b706ba98f02"
                    >
                      <CorporateFare className={classes.menuIcon} />
                      {dictionary.switch_company}
                    </MenuItem>
                  </>
                )}
              </div>
            )}

            <Divider />

            {/* Logout */}
            <MenuItem
              onClick={logout}
              data-cy={'logout-menu-link'}
              data-testid="MenuItem-a303538f-7ade-457c-af27-26616e912b9b"
            >
              <Logout className={classes.menuIcon} />
              {dictionary.logout}
            </MenuItem>
          </>
        )}
      </MenuList>
    </Paper>
  )
}

export default PopoverMenu
