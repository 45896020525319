import { createTheme } from '@mui/material/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const themeOptions = createTheme({
  spacing: 6,
  palette: {
    mode: 'light',
    primary: {
      main: '#7662EA',
      light: '#e5e9ff',
      xtraLight: '#f6f7fa',
      midlight: '#659ff7',
      dark: '#5840e1', // '#002766',
      contrastText: '#fff', // Should be #000 but our Design System use #000
      medium: '#d7d7f4'
    },
    secondary: {
      main: '#7966eb',
      light: '#dce0e3',
      xtraLight: '#f6f7fa',
      midlight: '#659ff7',
      dark: '#002766',
      contrastText: '#fff', // Should be #000 but our Design System use #000
      medium: '#9cc4ff'
    },
    help: {
      main: '#9247FD',
      light: '#B890F4',
      dark: '#6825CE',
      contrastText: '#fff',
      xtraLight: '#dfc6ff'
    },
    success: {
      main: '#008000',
      light: '#C3FECF'
    },
    error: {
      main: '#EE0701',
      // light: '#FFB5B3',
      light: '#e3b9b9'
    },
    warning: {
      main: '#F2BB2E',
      light: '#FBE8B7',
      contrastText: '#fff'
    },
    dark: {
      main: '#15171c'
    },
    text: {
      primary: '#505565', // body
      secondary: '#232735', // header
      disabled: '#8B8A93',
      // light: '#6E7388',
      light: '#6B6E75',
      darker: '#1D1F24',
      dark: '#3A3D44',
      empty: '#939393',
      // extralight: '#9e9e9e',
      extralight: '#A3A5AB'
    },
    elements: {
      main: '#F0F0F5',
      light: '#EBEFF3',
      white: '#FFFFFF'
    },
    highlight: {
      red: '#F53B57',
      lightRed: '#feebeb',
      // violet: '#3C40C6',
      violet: '#d378f3',
      lightPurple: '#ECE9FF',
      // blue: '#0FBCF9',
      blue: '#366BFF',
      lightBlue: '#E4E9FE',
      cyan: '#00D8D6',
      // green: '#05C46B',
      green: '#429482',
      // lightGreen: '#e2f4ef',
      lightGreen: '#E2F3F0',
      // yellow: '#FFC048',
      yellow: '#EBA741',
      lightYellow: '#FCF2E3',
      gold: '#948742',
      lightGold: '#F3E9DD',
      contrastText: '#fff',
      lightBlack: '#F4F6F9'
    },
    accent: {
      main: '#FF9900',
      light: '#FFEBCC'
    },
    other: {
      stroke: '#D3D4D8',
      filled: '#DADADA',
      disabled: '#E0E0E0',
      extralight: '#F2F3F4'
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: 96,
      lineHeight: 'auto',
      letterSpacing: '0.01px',
      color: '#232735'
    },
    h2: {
      fontWeight: 400,
      fontSize: 60,
      lineHeight: 'auto',
      letterSpacing: '-0.05px',
      color: '#232735'
    },
    h3: {
      fontWeight: 400,
      fontSize: 48,
      lineHeight: 'auto',
      letterSpacing: '0px',
      color: '#232735'
    },
    h4: {
      fontWeight: 400,
      // fontSize: 34,
      fontSize: 30,
      lineHeight: '48px',
      letterSpacing: '0.25px',
      color: '#232735'
    },
    h5: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: 'auto',
      letterSpacing: '0px',
      color: '#232735'
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 'auto',
      letterSpacing: '0.15px',
      color: '#232735'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#97999f'
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 12.5,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#97999f'
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 'auto',
      letterSpacing: '0.5px',
      color: '#505565'
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#505565'
    },
    button: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 'auto'
    },
    caption: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: 'auto',
      letterSpacing: '0.4px'
    },
    overline: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '12px',
      letterSpacing: '1.5px'
    },
    changeOption: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#7662EA',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    changeOptionSmall: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#1AB394',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    changeOptionSmallLight: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    changeOptionDark: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#505565',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    changeOptionLight: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#939393',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    changeOptionSec: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#0062FF',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    linkOption: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#1AB394',
      cursor: 'pointer'
    },
    linkHover: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#6E7388',
      cursor: 'pointer',
      '&:hover': {
        color: '#1AB394'
      }
    },
    placeholder: {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#505565',
      textDecoration: 'underline',
      fontStyle: 'italic'
    }
  }
})

const ootiTheme = createTheme(themeOptions, {
  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1536
    }
  }),
  components: {
    MuiBadge: {
      defaultProps: {
        max: 999
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
        stickyHeader: true // no IE 11 support
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderRadius: themeOptions.spacing(1),
          background: themeOptions.palette.elements.light,

          '& tr th': {
            '&:first-of-type': {
              borderTopLeftRadius: themeOptions.spacing(1)
            },
            '&:last-child': {
              borderTopRightRadius: themeOptions.spacing(1)
            }
          }
        }
      }
    },
    MuiTableRow: {
      defaultProps: {
        hover: true
      },
      styleOverrides: {
        hover: {
          backgroundColor: `${themeOptions.palette.elements.main} !important`
        }
      }
    },
    MuiTableCell: {
      defaultProps: {
        align: 'left'
      },
      styleOverrides: {
        root: {
          ...themeOptions.typography.subtitle2,
          // minHeight: '56px',
          // minWidth: '109px',
          color: themeOptions.palette.text.primary,
          padding: '8px 12px'
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          left: 0,
          bottom: 0, // <-- KEY
          zIndex: 2,
          width: '100%',
          position: 'sticky',
          backgroundColor: themeOptions.palette.elements.white,
          '&:first-of-type td': { borderTop: '1px solid #D3D4D8' }
          // '&:last-child td, &:last-child th': { borderBottom: 0 }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...themeOptions.typography.subtitle2,
          color: themeOptions.palette.text.primary
        }
        // '&.MuiFormControlLabel-label': checked && {
        //   color: themeOptions.palette.primary.main
        // }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          width: themeOptions.spacing(2),
          height: themeOptions.spacing(2),
          color: themeOptions.palette.elements.main,
          background: themeOptions.palette.elements.white,
          borderRadius: 6
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        },
        tooltip: {
          whiteSpace: 'pre-wrap'
        }
      }
    },
    MuiChip: {
      defaultProps: {
        size: 'medium'
      },
      styleOverrides: {
        root: {
          ...themeOptions.typography.body2,
          fontSize: '12px',
          color: themeOptions.palette.text.darker,
          backgroundColor: themeOptions.palette.elements.main,
          borderRadius: '8px'
        }
      }
    },
    MuiIcon: {
      defaultProps: {
        fontSize: 'medium'
      }
    },
    MuiAvatar: {
      defaultProps: {
        sx: {
          width: themeOptions.spacing(4),
          height: themeOptions.spacing(4),
          color: themeOptions.palette.secondary.main,
          backgroundColor: themeOptions.palette.secondary.light
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: 240, // drawerWidth
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            // width: 290, // drawerWidth
            boxSizing: 'border-box'
          }
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        position: 'fixed'
      },
      styleOverrides: {
        root: {
          height: themeOptions.spacing(6),
          zIndex: themeOptions.zIndex.drawer + 1,
          background: '#043176',
          boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.25)'
        }
      }
    },
    MuiToolbar: {
      defaultProps: {
        disableGutters: true
      },
      styleOverrides: {
        root: {
          paddingLeft: '22px',
          minHeight: '50px !important',
          paddingRight: themeOptions.spacing(1)
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          ...themeOptions.palette.body1
        },
        secondary: {
          ...themeOptions.palette.body2
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            boxShadow: `inset -2px 0px 0px ${themeOptions.palette.primary.main}`
          }
        }
      }
    },
    // Define color directly on the component
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        size: 'small'
      },
      styleOverrides: {
        root: {
          minWidth: '40px',
          minHeight: '34px',
          borderRadius: themeOptions.spacing(2),

          '& .MuiButton-startIcon': {
            userSelect: 'none'
          }
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        size: 'small'
      },
      styleOverrides: {
        root: {
          minWidth: '40px',
          minHeight: '36px',

          '& .MuiButton-startIcon': {
            userSelect: 'none'
          }
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        variant: 'fullWidth'
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: themeOptions.palette.text.disabled
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: themeOptions.spacing(3)
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiDivider: {
      // styleOverrides: {
      //   root: {
      //     marginTop: themeOptions.spacing(1),
      //     marginBottom: themeOptions.spacing(1)
      //   }
      // }
    },
    MuiIconButton: {
      defaultProps: {
        sx: {
          color: themeOptions.palette.primary.main
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: themeOptions.palette.secondary.main,
          width: 100,
          height: 8,
          '& .MuiSlider-track': {
            // border: 'none'
          },
          '& .MuiSlider-rail': {
            backgroundColor: themeOptions.palette.elements.main,
            opacity: 1
          },
          '& .MuiSlider-thumb': {
            height: 16,
            width: 16,
            backgroundColor: themeOptions.palette.elements.white,
            boxShadow: '-2px 0px 4px 0px rgba(0, 0, 0, 0.25)',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: '-2px 0px 4px 0px rgba(0, 0, 0, 0.25)'
            },
            '&:before': {
              display: 'none'
            }
          },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: themeOptions.palette.secondary.main,
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
              transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
            },
            '& > *': {
              transform: 'rotate(45deg)'
            }
          }
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
          // '&:focus': {
          //   backgroundColor: `${themeOptions.palette.elements.light} !important`
          // },

          '& .MuiInputLabel-root:not(.Mui-focused)': {
            color: themeOptions.palette.text.light,
            fontSize: '13px'
          },

          '& .MuiInputLabel-shrink': {
            '&.MuiInputLabel-sizeSmall': {
              transform: 'translate(14px, 5px)',
              fontSize: '10px'
            }
          },

          '& .MuiTextField-root': {
            border: 0
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: themeOptions.spacing(1)
        }
      }
    },
    // MuiInputAdornment: {
    //   styleOverrides: {
    //     root: {
    //       position: 'absolute',
    //       right: 0
    //     }
    //   }
    // },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          color: themeOptions.palette.primary.main
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: themeOptions.palette.primary.main
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '44px',
          '&.MuiInputBase-multiline': { height: 'unset' }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: `1px solid ${themeOptions.palette.other.stroke}`,
          borderRadius: themeOptions.spacing(1),
          // paddingRight: 0,

          '&:before, &:after': {
            borderBottom: 'none'
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'none'
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none'
          }
        },
        input: {
          position: 'relative',
          width: '100%',
          // paddingBottom: '6px',
          // paddingLeft: '12px',
          // paddingRight: '12px',
          fontSize: '13px',
          color: themeOptions.palette.text.dark,
          display: 'flex',
          alignItems: 'center'
          // '&:valid': {
          //   border: 'none',
          //   backgroundColor: themeOptions.palette.success.light
          // },
          // '&:invalid': {
          //   border: 'none',
          //   backgroundColor: themeOptions.palette.error.light
          // }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        size: 'small',
        minRows: 5,
        maxRows: 15
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: themeOptions.palette.primary.main
          },

          '& .MuiInputLabel-root:not(.Mui-focused)': {
            color: themeOptions.palette.text.light
          }
        }
      }
    },
    MuiLinearProgress: {
      defaultProps: {
        variant: 'determinate'
      },
      styleOverrides: {
        root: {
          height: themeOptions.spacing(1),
          backgroundColor: themeOptions.palette.elements.main,
          borderRadius: themeOptions.spacing(1)
        },
        bar1Determinate: {
          height: themeOptions.spacing(1),
          backgroundColor: themeOptions.palette.secondary.main,
          borderRadius: themeOptions.spacing(1)
        }
      }
    },

    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: themeOptions.spacing(5),
          display: 'flex',
          justifyContent: 'flex-end'
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          padding: themeOptions.spacing(1),
          ...themeOptions.typography.changeOptionDark,
          color: themeOptions.palette.secondary.main
        }
      }
    }
  }
})

export default ootiTheme
