import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Box,
  Toolbar,
  CssBaseline,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Drawer,
  Stack,
  IconButton,
  Collapse,
  ListItemText,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  Fade
} from '@mui/material'
import { ArrowDropUp, ArrowDropDown, ArrowUpward } from '@mui/icons-material'

import routes from '@config/routes'
import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation'

import useStyles from '@components/Header/styles'
import DrawerContent from './DrawerContent'
import TopBar from './TopBar'
import NoPermissionPage from '@components/common/NoPermissionPage'

export const MenuTitle = ({
  title,
  needMargin = true,
  innerSideMenu = true
}) => {
  return (
    <ListItem
      sx={{ pl: 0, ml: '28px', py: '10px', mt: needMargin ? '8px' : 0 }}
    >
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ textTransform: innerSideMenu ? 'capitalize' : 'uppercase' }}
        data-testid="Typography-61f807bd-d3fc-4639-8de8-882f14a86135"
      >
        {title}
      </Typography>
    </ListItem>
  )
}

export const CollapseMenuTitle = ({
  title,
  open = false,
  typeLink = false,
  path = null,
  selected = false,
  children
}) => {
  const router = useRouter()
  const [openMenu, setOpenMenu] = useState(open)

  return (
    <>
      <ListItemButton
        selected={selected}
        sx={{ py: 1, px: 3, boxSizing: 'border-box' }}
        onClick={() => {
          if (typeLink) router.push(path)
          else setOpenMenu(prev => !prev)
        }}
        data-testid="ListItemButton-ebb69394-16f2-4839-a8ce-45652047a972"
      >
        <ListItemText
          primary={
            <Typography
              variant="subtitle2"
              color="text.primary"
              data-testid="Typography-d03e55f4-5949-4705-b73e-4590c17b818d"
            >
              {title}
            </Typography>
          }
        />

        {!typeLink && (
          <>
            {openMenu ? (
              <ArrowDropUp color="primary" />
            ) : (
              <ArrowDropDown color="primary" />
            )}
          </>
        )}
      </ListItemButton>
      <Collapse in={openMenu} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}

export const MenuLink = ({
  path = '',
  title,
  icon,
  innerSideMenu = true,
  isSubmenu = false,
  subtitleVariant = false,
  customMargin = null,
  baseURL = null,
  secondaryPaths = null,
  handleDrawerMobileClose = () => {},
  endornment,
  opportunitiesIDs = []
}) => {
  const { asPath, query } = useRouter()
  const isInnerSideMenuColor = innerSideMenu ? 'primary' : 'secondary'
  const isInnerSideMenuIconColor = innerSideMenu
    ? 'primary.main'
    : 'secondary.main'

  let isSelected
  // if (!baseURL) isSelected = path === asPath.split('?')[0]
  // else isSelected = asPath.includes(baseURL)

  if (baseURL) {
    // isSelected = asPath.includes(baseURL)
    if (
      baseURL === '/project' &&
      query?.id &&
      opportunitiesIDs.includes(+query.id)
    ) {
      isSelected = false
    } else if (
      baseURL === '/opportunities' &&
      query?.id &&
      opportunitiesIDs.includes(+query.id)
    ) {
      isSelected = true
    } else {
      const rootPath = asPath.split('/').filter(p => !!p)[0]
      isSelected = baseURL.includes(rootPath)
    }
  } else {
    isSelected =
      path === asPath.split('?')[0] ||
      (secondaryPaths && secondaryPaths.includes(asPath.split('?')[0])) ||
      (title === 'Opportunities' && asPath.includes('opportunities')) ||
      (asPath.includes('project') &&
        query?.id &&
        opportunitiesIDs.includes(+query.id))
  }
  const innerSideMenuVariant = isSubmenu ? 'subtitle2' : 'body2'
  const closeMobileMenuHandler = () => {
    handleDrawerMobileClose()
  }
  return (
    <Link href={path}>
      <a>
        <ListItemButton
          onClick={() => {
            if (window.innerWidth < 960) {
              closeMobileMenuHandler()
            }
          }}
          selected={isSelected}
          sx={{
            pl: 0,
            py: '8px',
            '&.Mui-selected': {
              '&:hover': {
                background: theme =>
                  innerSideMenu
                    ? theme.palette.primary.light
                    : theme.palette.secondary.light
              },
              background: theme =>
                innerSideMenu
                  ? theme.palette.primary.light
                  : theme.palette.secondary.light,
              boxShadow: theme =>
                `inset -2px 0px 0px ${
                  innerSideMenu
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main
                }`
            }
          }}
          data-testid="ListItemButton-1a07fac1-862e-439c-acc6-0a7dc742d5a5"
        >
          {icon && !subtitleVariant && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                // ml: '28px',
                pl: '28px',
                mr: '12px',
                color: isSelected ? isInnerSideMenuIconColor : 'text.primary'
              }}
            >
              {icon}
            </ListItemIcon>
          )}

          {icon && subtitleVariant && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                // ml: '28px',
                pl: '40px',
                mr: '0',
                color: isSelected ? isInnerSideMenuIconColor : 'text.primary'
              }}
            >
              {icon}
            </ListItemIcon>
          )}

          <Typography
            marginLeft={
              subtitleVariant && !icon
                ? '64px'
                : subtitleVariant && icon
                ? '32px'
                : customMargin || 0
            }
            variant={
              isSelected
                ? 'subtitle2'
                : innerSideMenu
                ? innerSideMenuVariant
                : 'body2'
            }
            color={isSelected ? isInnerSideMenuColor : 'text.primary'}
            data-testid="Typography-bd9e6186-ce7f-4285-9842-e30578b028ca"
          >
            {title}
          </Typography>
          {endornment && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                // ml: '28px',
                pl: '40px',
                mr: '0',
                color: isSelected ? isInnerSideMenuIconColor : 'text.primary'
              }}
            >
              {endornment}
            </ListItemIcon>
          )}
        </ListItemButton>
      </a>
    </Link>
  )
}
export default function ClippedDrawer({ children }) {
  const classes = useStyles()
  const { dictionary } = useTranslation()
  const { isAuthenticated, organization, membership, user } = useAuth()
  const router = useRouter()

  /* Scroll to top button */
  const [showButton, setShowButton] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    })
  }
  /*****/

  // const [opportunities, setOpportunites] = useState(null)
  // const [projects, setProjects] = useState(null)
  // const [teams, setTeams] = useState(null)
  // const container =
  //   window !== undefined ? () => window().document.body : undefined

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  if (!isAuthenticated) {
    return (
      <div className={classes.grow}>
        <TopBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {children}
        </Box>
      </div>
    )
  }

  const isPageAccount = router.asPath.includes(routes.account.root)
  const isTenantPage = user.role === 'tenant' // router.asPath.includes(routes.tenant.root)

  return (
    <Stack sx={{ minHeight: '100vh' }} justifyContent="space-between">
      <Box display="flex">
        <CssBaseline />
        <TopBar opportunities={[]} handleDrawerToggle={handleDrawerToggle} />
        {organization && !isPageAccount && !isTenantPage && (
          <Box component="div" id="sideMenu">
            <Drawer
              // container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              classes={{
                root: classes.menuDrawer,
                paper: classes.menuDrawer
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: '100%'
                }
              }}
            >
              <DrawerContent
                opportunitiesIDs={[]}
                handleDrawerToggle={handleDrawerToggle}
              />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', md: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: 240,
                  borderRight: 'none'
                }
              }}
              classes={{
                root: classes.menuDrawer,
                paper: classes.menuDrawer
              }}
              open
            >
              <DrawerContent
                opportunitiesIDs={[]}
                handleDrawerToggle={handleDrawerToggle}
              />
            </Drawer>
          </Box>
        )}

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {membership && membership?.has_access && children}
          {membership && !membership?.has_access && <NoPermissionPage />}
          {!membership && children}
        </Box>
      </Box>

      <Fade in={showButton}>
        <IconButton
          sx={{
            bgcolor: '#c9c9c99f',
            '&:hover': { bgcolor: '#c9c9c9c2' },
            position: 'fixed',
            bottom: '60px',
            left: '300px',
            zIndex: 1000
          }}
          onClick={scrollToTop}
          data-testid="IconButton-cb0dd67d-10cc-4c3c-a606-a4c5bd793172"
        >
          <ArrowUpward color="primary" />
        </IconButton>
      </Fade>

      <Paper elevation={3}>
        <BottomNavigation
          showLabels
          // value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
        >
          <BottomNavigationAction
            label={
              <a
                href={'https://keysy.co/conditions-generales/'}
                target="_blank"
                rel="noreferrer"
              >
                {dictionary.privacy}
              </a>
            }
          />

          <BottomNavigationAction
            label={
              <a href={'https://keysy.co/'} target="_blank" rel="noreferrer">
                {dictionary.website}
              </a>
            }
          />
        </BottomNavigation>
      </Paper>
    </Stack>
  )
}
